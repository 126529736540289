.xfx-notification-mode .dx-widget.dx-collection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
}

.xfx-notification-mode .dx-widget.dx-collection .dx-item{
    margin-right: 50px;
}

.xfx-notification-mode-settings .dx-widget.dx-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: flex-start;
}


.xfx-notification-field-hidden {
    display: none;
}
